<template>
  <list-container-widget
    id="projects"
    :title="widgetName"
    :app-id="1"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="projects" />
    </template>

    <b-card-body class="x-scroll-container">
      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0 && canList" class="cards-container">
        <div ref="container" class="x-scroll-container">
          <!-- Items Loop -->
          <div
            v-for="item in items"
            :key="item.key"
            class="x-scroll-element mx-1 mb-3 p-0"
          >
            <project-card v-if="item.isPublished || (!item.isPublished && isStaff)" :item="item" />
          </div>

          <!-- Buttons prev and next -->
          <button 
            class="link-previous btn btn-icon btn-primary btn-previous" 
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else-if="!canList" class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="projectPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('no-permisions.message') }}
          </p>
        </b-col>
      </b-row>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="projectPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- App info -->
    <description-addon 
      v-model="isAppInfoModalVisible" :app-id="1" 
    />
  </list-container-widget>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
// import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import Ripple from 'vue-ripple-directive';
import { checkPermissions } from '@/@core/utils/roles-utils';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
import ProjectPlaceholder from '@/assets/images/placeholders/light/projects.svg';
import { quillEditor } from 'vue-quill-editor';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import ProjectCard from '../components/ProjectCard.vue';

export default {
  name: 'ProjectListWidget',
  components: {
    DescriptionAddon,
    quillEditor,
    ListContainerWidget,
    ProjectCard,
    LanguageSelectorHeader,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  directives: {
    Ripple,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      // placeholder: RoomsPlaceholder,
      loadingNext: false,
      projects: [],
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      projectInput: {},
      isAppInfoModalVisible: false,
      imageProject: null,
      imageSrc: null,
      selectedLanguage: this.currentLocale,
      searchInput: '',
      results: [],

      // Filters data
      // areFiltersVisible: false,
      // nextFilter: null,
      // selectedClassifiersForFilter: [],
      // finalSelectClassifier: [],
      // customFieldToSend: {},
      // selectedCustomFieldsForFilter: null,
      // showfilter: false,
      isModalVisible: false,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    // Filtros computed
    // classifiers() {
    //   if (this.$store.getters.classifiers.projects) {
    //     return this.$store.getters.classifiers.projects.unpaginated;
    //   }
    //   return [];
    // },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    // sortedCommunityClassifiers() {
    //   const newObject = {};
    //   for (const item of this.classifiers) {
    //     if (!newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)]) {
    //       newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)] = [item];
    //     } else {
    //       newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)].push(item);
    //     }
    //   }
    //   const keys = Object.keys(newObject);
    //   const finalKeys = [];
    //   for (const row of keys) {
    //     finalKeys.push({
    //       key: row,
    //       name: row,
    //       type: 1,
    //     });
    //   }
    //   return [newObject, finalKeys];
    // },
    // finalOptions() {
    //   return [].concat(this.sortedCommunityClassifiers[1], this.customFieldsOptions);
    // },
    // Final filtros computed

    // Load data from store
    itemsData() {
      return this.$store.getters.communitiesOthers.projects;
    },
    items() {
      if (this.itemsData) {
        const itemsToIterate = this.searchInput ? this.results : this.itemsData.unpaginated;
        return itemsToIterate;
      }
      return [];
    },
    projectPlaceholder() {
      return ProjectPlaceholder;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions('create', 'project', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
    canList() {
      return checkPermissions('view', 'project', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
    canView() {
      return checkPermissions('view', 'project', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
  },
  watch: {
    imageProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData(true);
    // await this.fetchClassifiers();
    this.isLoading = false;
  },
  methods: {
    timezone(timestamp) {
      return DateTime.fromISO(timestamp).offsetNameShort;
    },
    // canFilter() {
    //   if (this.classifiers.length > 0) {
    //     this.showfilter = true;
    //   } else {
    //     this.showfilter = false;
    //   }
    // },
    // Filtros methods
    // toggleFilters() {
    //   this.areFiltersVisible = !this.areFiltersVisible;
    //   if (!this.areFiltersVisible) {
    //     this.customFieldToSend = null;
    //     this.finalSelectClassifier = [];
    //     (this.selectedCustomFieldsForFilter = []), (this.nextFilter = null);
    //     (this.total = 0),
    //     (this.filtersIndex = 0),
    //     (this.filters = []),
    //     (this.parentClassifiers = []),
    //     (this.selectedClassifiersForFilter = []);
    //     this.customFieldsInputs = [];
    //     this.index = 0;
    //     this.fetchData(true);
    //   }
    // },
    handleChanger() {
      this.fetchData(true);
    },
    // async setSeletedFinalFilter(selected) {
    //   if (selected && selected.type === 2) {
    //     this.customFieldToSend[selected.key] = selected.text;
    //   } else if (selected) {
    //     this.finalSelectClassifier = selected.key;
    //   } else {
    //     this.finalSelectClassifier = [];
    //   }
    //   this.handleChanger();
    // },
    // async fetchClassifiers() {
    //   await this.$store.dispatch('getItems', {
    //     itemType: 'classifiers',
    //     storedKey: 'projects',
    //     page: 1,
    //     requestConfig: {
    //       morphType: 'projects',
    //       count: 1000,
    //     },
    //   });
    //   this.canFilter();
    //   this.isLoading = false;
    // },
    // async setSeletedFilter(selected) {
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected.length === 0) {
    //     this.finalSelectClassifier = null;
    //   } else {
    //     let translated = [];
    //     if (selected.type === 1) {
    //       for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
    //         translated.push({
    //           name: translateTranslationTable(this.$store.state.locale.currentLocale, row.name),
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = '';
    //       translated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     this.nextFilter = translated;
    //   }
    // },
    // Final filtros methods

    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "projects",
        page: this.lastLoadedPage,
        perPage: 30,
        forceAPICall: force,
        requestConfig: {
          isProject: true,
          orderByDate: -1,
          communityParentSlug: this.$store.getters.currentCollective.slug,
          // classifiers: this.finalSelectClassifier,
          // customFields:
          //   this.customFieldToSend != null && Object.keys(this.customFieldToSend).length > 0
          //     ? this.customFieldToSend
          //     : null,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.srcElement.scrollWidth - event.srcElement.scrollLeft - event.srcElement.offsetWidth;
      if (distanceToEnd < 10 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    // async handleLoadOfNewItems() {
    //   const { total } = this.itemsData.meta;
    //   if (!this.isLoading && this.items.length <= total) {
    //     this.lastLoadedPage += 1;
    //     this.isLoadingNextPage = true;
    //     await this.fetchData();
    //     this.isLoadingNextPage = false;
    //   }
    // },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreate() {
      if (!this.projectInput.name) {
        return;
      }
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'projects',
            requestConfig: {
              name: this.projectInput.name,
              description: this.projectInput.description,
              headline: this.projectInput.headline,
            },
          },
          file: this.imageProject,
        });
        this.fetchData(true);
        this.projectInput = {};
        this.imageProject = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleDateString(this.$store.state.locale.currentLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: 'projects',
        morphType: 'community',
        key: item.key,
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
    closeModal() {
      this.$bvModal.hide('modal-create-projects');
    },
  },
};
</script>

<style>
.icon-container {
  background-color: white;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.card-container {
  height: 375px;
}
.content-container img {
  max-width: 100%;
}
.card-img {
  height: 160px !important;
}
.card {
  vertical-align: top !important;
}
</style>
